import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Loader, ErrorFallback } from 'smart-react';
import ProductList from '../../Core/ProductList/ProductList';
import { getPersonalTenant } from '../../../Utils/Auth/ValidatePermissions';
/**
 * Products main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Products component.
 */
const Products = () => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className='container mx-auto'>
        <React.Suspense fallback={<Loader />}>
          <ProductList
            isOrganizationProduct={false}
            tenantID={getPersonalTenant()?.TenantId}
            subscribed={false}
          />
        </React.Suspense>
      </div>
    </ErrorBoundary>
  );
};
export default Products;
